require('typeface-roboto')
require('typeface-roboto-condensed')

exports.onInitialClientRender = () => {
  window.onEventbriteLoad = function () {
    window.EBWidgets.createWidget({
      widgetType: 'checkout',
      eventId: '55446789841',
      modal: true,
      modalTriggerElementId: 'eventbrite-widget-modal-trigger-55446789841',
      onOrderComplete: function () {}
    })
  }
}
